import React, { useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/Layout";
import {
  Section,
  Content,
  H3,
  H2,
  Grid2,
  P,
  Line,
  Col,
  Arrow,
  Btn,
} from "../styles/shared";
import styled from "styled-components";
import Footer from "../shared/Footer";
import blue0 from "../images/BluCover.jpg";
import Menu from "../shared/Menu";
import redirect from "../shared/Redirect";
import { Helmet } from "react-helmet";

//#region COMPS
const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
`;
const Li2 = styled.li`
  color: rgb(123, 116, 110);
  font-size: 17px;
  font-family: "brandon", sans-serif;
  margin-bottom: -0.8rem;
  &:before {
    content: "• ";
    font-size: 28px;
    color: rgb(245, 169, 19);
    margin-right: 0.7rem;
  }
`;
const UL = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 15px;
  align-items: top;
`;
const Cricle = styled.div`
  content: "• ";
  color: rgb(245, 169, 19);
  font-size: 30px;
  line-height: 0.9;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
  gap: 40px;
`;

const PriceCard = styled.div`
  width: 350px;
  height: 130px;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
`;

const prices = [
  {
    from: "MAY 15",
    to: "MAY 31",
    price: "340",
  },
  {
    from: "JUN 1",
    to: "JUN 9",
    price: "369",
  },
  {
    from: "JUN 10",
    to: "Jun 19",
    price: "446",
  },
  {
    from: "Jun 20",
    to: "Jun 30",
    price: "534",
  },
  {
    from: "Jul 1",
    to: "Aug 31",
    price: "892",
  },
  {
    from: "Sep 1",
    to: "Sep 9",
    price: "534",
  },
  {
    from: "Sep 10",
    to: "Sep 19",
    price: "446",
  },
  {
    from: "Sep 20",
    to: "Sep 30",
    price: "369",
  },
  {
    from: "Oct 1",
    to: "Oct 31",
    price: "340",
  },
];
//#endregion

//#region DatePicker

//#endregion

export default function Booking() {
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <Helmet>
        <title>Book Your Stay – La Villa Bleue</title>
        <meta
          name="description"
          content="Check up-to-date availability and book instantly online using Hvar Away's secure booking and payment processor."
        />
      </Helmet>
      {!open ? (
        <>
          <Header bg={blue0} type="booking" setOpen={setOpen} />
          <Section>
            <Content>
              <H3 align="right">NIGHTLY RATES</H3>
              <PriceWrapper>
                {prices.map((rate, index) => (
                  <PriceCard key={index}>
                    <H2 align="right" style={{ textTransform: "uppercase" }}>
                      {rate.from} - {rate.to}
                    </H2>
                    <P align="right" color="rgb(123,116,110)">
                      €{rate.price}
                    </P>
                    <Line align="stretch" />
                  </PriceCard>
                ))}
                <P
                  align="right"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "auto", width: "350px" }}
                >
                  All prices are inclusive of tourist tax.
                </P>
              </PriceWrapper>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content>
              <Col align="start">
                <H3 align="left">ADDITIONAL INFORMATION</H3>
                <ul style={{ listStyle: "none", fontSize: "17px" }}>
                  <Li2>Check in from 16:00</Li2>
                  <Li2>Check out before 10:00</Li2>
                  <Li2>Minimum stay of 6 nights in summer</Li2>
                  <Li2>Cleaning fee of €200 per stay</Li2>
                  <Li2>Car is a requirement for access</Li2>
                  <Li2>50% payment required to secure booking</Li2>
                  <Li2>50% payment required 60 days before arrival</Li2>
                  <Li2>100% refund if cancelled 60 days before arrival</Li2>
                  <Li2>50% refund if cancelled 30 days before arrival</Li2>
                  <Li2>€750 damages deposit required</Li2>
                  <Li2>Pets and parties are not allowed</Li2>
                  <Li2>Smoking is allowed only in outdoor areas</Li2>
                </ul>
                <Btn
                  border="1px solid #3C85C6"
                  bgcolor="transparent"
                  width="335px"
                  color="#7B746E"
                  hborder="1px solid rgba(245, 169, 19, 0.8)"
                  hbgcolor="rgba(245, 169, 19, 0.8)"
                  hcolor="#fff"
                  style={{ marginTop: "40px" }}
                  onClick={() => redirect("/amenities")}
                >
                  <span>ALL AMENITIES</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section>
            <Content>
              <Col align="start">
                <H3 align="center" style={{ width: "100%" }}>
                  NOTEWORTHY DETAILS
                </H3>
                <UL>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    <strong>When we say secluded, we really mean it.</strong>{" "}
                    The villa is accessed down 4km of gravel road that starts
                    once you turn off the main road spanning the island, between
                    the villages of Gdinj and Zastražiš
                    <span style={{ fontSize: "14px" }}>ć</span>e. The drive down
                    to the bay takes about 10-15 minutes, maybe slightly longer
                    on your first time! The gravel road is rocky in places and
                    makes for a bumpy and sometimes steep ride down to the
                    house.{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Please be aware you will need to drive up/down this road
                      to reach the small village shops
                    </span>{" "}
                    (about 15-20 minutes) so we suggest stocking up on groceries
                    at the supermarket on your way to the villa. We also offer a
                    paid grocery delivery service upon request.{" "}
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    Bedroom IV is found on the villa's second floor and is
                    accessed only via an outdoor staircase from the first floor
                    terrace. This bedroom is a self-contained suite with an
                    adjoining bathroom.
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    Three out of the four bedrooms are air conditioned. Bedroom
                    I is equipped with a desk fan only – it is positioned
                    "underground" and therefore stays relatively cool in summer,
                    especially at night time.
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    A 4x4 or similar vehicle is recommended for making the
                    journey more comfortable, but it is not a necessity – many
                    guests and the locals of Medvidina Bay drive "normal" cars.
                    Please note that the gravel road, despite its drawbacks,
                    serves to ensure the beaches of bay stay quiet, even in the
                    peak of summer. Only those who really want a slice of true,
                    quiet paradise will reach this stunning location.
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    Please note that this is a sustainable, off-grid home, 100%
                    powered by solar. There is a large water tank onsite, but
                    there is a limit to the number of water deliveries we can
                    have on the island per year. While there is no restrictions
                    or limits for our guests and the villa is equipped for a
                    comfortable, well-facilitated stay, we ask that our guests
                    are simply mindful of these resources and conscious of where
                    the power and water come from.
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    If you wish to book a meal at Antun's small restaurant next
                    door to the villa, please let us know at least one day in
                    advance so that we can advise him to prepare for you.
                  </P>
                  <Cricle>•</Cricle>
                  <P color="rgb(123,116,110)" align="left">
                    This is a secluded location surrounded by nature, and
                    therefore you may be greeted with more insects in the
                    surrounding area than if you were staying in Split or
                    Dubrovnik.
                  </P>
                </UL>
                <Btn
                  border="1px solid #3C85C6"
                  bgcolor="transparent"
                  width="250px"
                  color="#7B746E"
                  hborder="1px solid rgba(245, 169, 19, 0.8)"
                  hbgcolor="rgba(245, 169, 19, 0.8)"
                  hcolor="#fff"
                  style={{ margin: "0 auto", marginTop: "30px" }}
                  onClick={() => redirect("/booking")}
                >
                  <span>BOOK NOW</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Footer type="booking" />
        </>
      ) : (
        <Menu setOpen={setOpen} />
      )}
    </Layout>
  );
}
